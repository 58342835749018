<template>
  <div class="recommends container content-wrapper" style="padding-top: 10px">
    <div class="header">
      <v-btn v-if="step > 2 && step < 5" class="back-btn" @click="prevStep()">
        <span class="mdi mdi-chevron-left"></span>
      </v-btn>
      <p v-if="step >= '1' && step <= '4'">退会手続き</p>
      <p v-if="step === '5'">退会手続き完了</p>
      <p v-if="step === '6' && !cancel_reason_submitted">認証確認</p>
    </div>
    <!-- step 1 -->
    <div v-show="!isLoading && step === '1'">
      <div class="container">
        <div class="white-card">
          <div class="container">
            <h2>本当に退会してよろしいでしょうか？</h2>
            <div class="text-content">
              <p style="line-height: 25px">
                毎日300人の女性がレコメンドで出会いを求めてます✨そして100件以上の出会いが発生中！
                新しい機能「出会いたい」では、女性がピンポイントで会いたい男性を求めています、、 ❤️（詳細はXの公式アカウント
                <span style="color:"><a href="https://x.com/honachan_2" target="_blank">@honachan_2</a></span>
                で募集中）
              </p>
            </div>
            <div class="group-btn">
              <v-btn class="btn-comp login-btn" @click="nextStep()">はい</v-btn>
              <v-btn class="btn-comp" @click="$router.push('/home')">いいえ</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- step 2 -->
    <div v-show="!isLoading && step === '2'">
      <div class="container">
        <div class="white-card">
          <div class="container">
            <h2>
              退会手続きを行います。
              <br />
              今後のサービス改善の為、退会理由をお教えください。
            </h2>
            <div class="text-content">
              <p>&#9724;退会の手続きの手順</p>
              <p>①下記ボタンより退会ページへ進む</p>
              <p>②必要事項を入力し退会手続きを行ってください。</p>
              <p>③決済会社より退会のメール受信で正式に退会完了となります。</p>
            </div>
            <v-btn class="btn-comp login-btn" @click="nextStep()">提出</v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- step 3 -->
    <div v-show="!isLoading && step === '3'">
      <div class="container">
        <div class="white-card">
          <div class="container">
            <h2>
              退会手続きを行います。
              <br />
              今後のサービス改善の為、退会理由をお教えください。
            </h2>
            <div class="title">
              <span>退会理由</span>
              <span class="required">必須</span>
            </div>
            <v-radio-group v-model="cancel_reason">
              <v-radio label="レコメンドされなかった" value="レコメンドされなかった" color="#f74481"></v-radio>
              <v-radio
                v-if="sex == 1"
                label="マッチンした女性と会うまでに至らなかった"
                value="マッチンした女性と会うまでに至らなかった"
                color="#f74481"
              ></v-radio>
              <v-radio
                v-if="sex == 2"
                label="マッチンした男性と会うまでに至らなかった"
                value="マッチンした男性と会うまでに至らなかった"
                color="#f74481"
              ></v-radio>
              <v-radio label="月額費が高い" value="月額費が高い" color="#f74481"></v-radio>
              <v-radio label="X(Twitter)を使わなくなった" value="X(Twitter)を使わなくなった" color="#f74481"></v-radio>
              <v-radio label="その他" value="その他" color="#f74481"></v-radio>
            </v-radio-group>
            <label v-show="cancel_reason == 'その他'" for="cancel_reason_text">よろしければ詳細をお聞かせください(100文字)</label>
            <v-textarea
              v-model="cancel_reason_text"
              :rules="myrules"
              v-show="cancel_reason == 'その他'"
              id="cancel_reason_text"
              counter="100"
              outlined
            ></v-textarea>
            <v-btn v-if="submittable" class="btn-comp login-btn" @click="nextStep()">提出</v-btn>
            <v-btn v-else class="btn-comp disabled-btn" disabled>提出</v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- step 4 -->
    <div v-show="!isLoading && step === '4'">
      <div class="container">
        <div class="white-card">
          <div class="container">
            <h2>
              退会手続きを行います。
              <br />
              最終確認でございます。
              <br />
              以下の回答内容で退会してよろしいでしょうか？
            </h2>
            <p class="title">退会理由</p>
            <v-radio-group v-model="cancel_reason">
              <v-radio disabled :label="cancel_reason" :value="cancel_reason"></v-radio>
            </v-radio-group>
            <label aria-disabled="true" v-show="cancel_reason == 'その他'" for="cancel_reason_text">
              よろしければ詳細をお聞かせください(100文字)
            </label>
            <v-textarea disabled v-model="cancel_reason_text" v-show="cancel_reason == 'その他'" background-color="#F2F2F7" outlined></v-textarea>

            <v-btn class="btn-comp login-btn" @click="nextStep()">提出</v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- step 5 -->
    <div v-show="!isLoading && step === '5'">
      <div class="container">
        <div class="white-card">
          <div class="container">
            <div class="text-content">
              <p>ご回答いただきありがとうございました。</p>
              <p>決済の退会が完了しましたら、正式に退会お手続きが完了いたします。</p>
              <p>この度は私達のサービスである性癖マッチンをご利用頂いただきまして、誠にありがとうございました。</p>
              <p>なお退会お手続きが完了後、いいねは全て無効となります。</p>
              <p>ご不明な点やご質問などがありましたら、カスタマーセンターにお問い合わせ下さい。</p>
              <p>またのご利用を心よりお待ち申し上げております。</p>
            </div>
            <v-btn class="btn-comp login-btn" @click="nextStep()">決済退会</v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- step 6 -->
    <div v-show="step === '6'">
      <div class="container">
        <div class="white-card" v-show="!cancel_reason_submitted">
          <div class="container">
            <p class="title">
              パスワード
              <span class="required">必須</span>
            </p>
            <input :disabled="isLoading" class="password-input" type="password" v-model="password" placeholder="パスワードを入力してください" />
            <p v-if="warning_flag" style="color: red; margin: 0">
              {{ message }}
            </p>
            <v-btn v-if="completable" :disabled="isLoading" class="btn-comp login-btn" @click="submit_cancel_reason()">
              <span v-if="isLoading"><Spinner></Spinner></span>
              <span v-else>決済退会</span>
            </v-btn>
            <v-btn v-else class="btn-comp disabled-btn" disabled>決済退会</v-btn>
          </div>
        </div>

        <div v-show="cancel_reason_submitted && !warning_flag">
          <div class="white-card">
            <div class="container">
              <h3>決済退会完了</h3>
              <router-link to="/home">
                <v-btn class="btn-comp login-btn">ホームへ</v-btn>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  h2 {
    margin: 8px 0 !important;
  }

  .header {
    position: relative;
    margin: 15px;
  }
  .header p {
    font-size: 20px;
    font-weight: bold;
  }
  .back-btn {
    position: absolute;
    left: 0;
    min-width: 30px !important;
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none;
  }
  .back-btn span {
    font-size: 30px;
  }
  .btn-comp {
    color: rgb(248, 70, 129);
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    color: white;
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }
  .disabled-btn {
    background: gray;
    color: #999999;
  }
  [aria-disabled='true'] {
    color: rgba(0, 0, 0, 0.38);
  }
  label {
    font-size: 0.8rem;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    text-align: left !important;
  }
  .title {
    font-size: 1rem !important;
    font-weight: 900 !important;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #4b4b4b;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 5px !important;
  }
  .required {
    border-radius: 4px;
    height: 17px;
    text-align: center;
    padding: 4px;
    background: #bf0000;
    color: white;
    font-size: 0.5rem;
    line-height: 10px;
  }
  .text-content {
    text-align: start;
    margin-top: 20px;
  }

  .password-input {
    width: 100%;
    margin: 10px 0px;
    padding: 5px;
    border: 1px solid #757575;
    border-radius: 5px;
  }

  .password-input:focus-visible {
    outline: none;
  }
  .white-card {
    margin-bottom: 50px !important;
  }

  @media only screen and (min-width: 480px) {
    .content-wrapper {
      margin-top: 0 !important;
    }
  }
  @media only screen and (min-width: 768px) {
    .group-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }
</style>

<script>
  import axios from '../../util/axios'
  import Spinner from 'vue-simple-spinner'

  export default {
    name: 'PaymentCancellation',
    data() {
      return {
        myrules: [(v) => v.length <= 100 || '100文字以内で入力してください。'],
        isLoading: true,
        completedSteps: 0,
        cancel_reason: '',
        cancel_reason_submitted: false,
        cancel_reason_text: '',
        uid: '',
        client: '',
        access_token: '',
        email: '',
        twitterid: '',
        info: '',
        info_ts: '',
        payment_conf: '',
        payment_kind: 0,
        age_conf: '',
        data_all: '',
        rank: '-',
        sex: '',
        matching_count: 0,
        recommend_count: 0,
        site_id: '',
        site_pass: '',
        payment_id: '',
        payment_pass: '',
        warning_flag: false,
        payment_resource: '',
        step: '',
        password: '',
      }
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.sex = localStorage.getItem('sex')
      this.getAccountData()
      if (this.uid === null) {
        this.$router.push('/login')
      }
    },
    computed: {
      submittable: function () {
        if (this.cancel_reason != '' && this.cancel_reason != null && this.cancel_reason != 'その他') return true
        else if (this.cancel_reason == 'その他' && this.cancel_reason_text.length < 101) return true
        else return false
      },
      completable: function () {
        if (this.password != '' && this.password != null) return true
        else return false
      },
    },
    watch: {
      '$route.query': {
        immediate: true,
        handler(newQuery) {
          const step = parseInt(newQuery.step || '1', 10)
          if (!newQuery.step || isNaN(step) || step < 1 || step > 6) {
            this.$router.replace({
              query: {
                ...this.$route.query,
                step: '1',
              },
            })
            return
          }
          this.step = step.toString()
          if (step >= 3 && step <= 6 && !this.submittable) {
            this.cancel_reason = 'レコメンドされなかった'
            this.$router.replace({
              query: {
                ...this.$route.query,
                step: '3',
              },
            })
          }
        },
      },
    },
    methods: {
      async submit_cancel_reason() {
        this.isLoading = true
        this.cancel_reason_submitted = false
        this.clearWarnings()

        const headers = {
          uid: this.uid,
          'access-token': this.access_token,
          client: this.client,
          'Content-Type': 'application/json',
        }

        try {
          await axios.post('/api/cancel_reason', {
            headers,
            cancel_reason: {
              uid: this.uid,
              password: this.password,
              cancel_reason: this.cancel_reason,
              cancel_reason_text: this.cancel_reason_text,
            },
          })

          await axios.post('/api/al_payment/cancellation/submit', { uid: this.uid }, { headers })

          this.warning_flag = false
          this.cancel_reason_submitted = true
        } catch (error) {
          this.warning_flag = true
          this.message = error.response?.data?.msg || 'An error occurred'
        } finally {
          this.isLoading = false
        }
      },
      async getAccountData() {
        const result = await axios
          .get('/api/payment_information', {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
          })
          .catch((e) => {
            e
          })

        this.isLoading = false
        this.payment_id = result.data.payment_id
        this.payment_pass = result.data.payment_pass
        this.payment_conf = result.data.payment_conf
        this.payment_kind = result.data.payment_kind
        this.payment_resource = result.data.payment_resource
        if (this.payment_kind == 2500) {
          this.site_id = '32307805'
          this.site_pass = '6qoFpigk'
        } else if (this.payment_kind == 6600) {
          this.site_id = '32307806'
          this.site_pass = 'Fbc5xrWe'
        } else if (this.payment_kind == 12000) {
          this.site_id = '32307807'
          this.site_pass = 'TSRMPfZE'
        } else if (this.payment_kind == 20000) {
          this.site_id = '32307810'
          this.site_pass = 'sSB5Qm3w'
        }
        this.email = result.data.email
        if (this.matching_count == null) {
          this.matching_count = 0
        }
        if (this.recommend_count == null) {
          this.recommend_count = 0
        }
        if (this.payment_conf != '1') {
          this.$router.push('/home')
        }
      },
      clearWarnings() {
        this.message = ''
        this.warning_flag = false
      },
      nextStep() {
        const currentStep = parseInt(this.$route.query.step || '1', 10)
        const nextStep = currentStep + 1

        if (nextStep <= 6) {
          if (nextStep === 3) {
            this.cancel_reason = 'レコメンドされなかった'
          }
          if (nextStep === 4 && this.cancel_reason !== 'その他' && !!this.cancel_reason_text) {
            this.cancel_reason_text = ''
          }
          if (nextStep === 6 && !!this.password) {
            this.password = ''
          }
          this.$router.push({
            query: { ...this.$route.query, step: nextStep.toString() },
          })
        }
        this.clearWarnings()
      },
      prevStep() {
        const currentStep = parseInt(this.$route.query.step || '1', 10)
        const prevStep = currentStep - 1

        if (prevStep > 1) {
          this.$router.push({
            query: { ...this.$route.query, step: prevStep.toString() },
          })
        } else {
          this.$router.push('/payment_information')
        }
        this.clearWarnings()
      },
    },
    components: {
      Spinner,
    },
  }
</script>
