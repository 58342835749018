<template>
  <v-app>
    <div v-if="isLoading" style="padding-top: 60px">
      <Spinner></Spinner>
    </div>

    <div class="login" v-else>
      <div class="container" style="overflow-x: hidden">
        <div class="container" v-if="form.twitter_name == null">
          <div class="white-card">
            <h1 style="margin-bottom: 0; font-size: 1.23rem; color: #4b4b4b">プロフィール登録</h1>
          </div>
        </div>
        <div>{{ this.message }}</div>
        <div style="text-align: left">
          <div class="form-group" style="max-width: 90%; display: inline-block" id="profile-img-section">
            <label for="form_thumbnail">プロフィール写真</label>
            <p>
              好きな趣味やSNSのアイコンを設定してください！
              <br />
              ⚠️QRコードや性器の写真は削除対象となります！
            </p>

            <v-container v-show="!sample_img_section">
              <v-row>
                <v-col cols="12" class="white-card">
                  <p style="font-weight: 900">メイン写真(雰囲気写真など)</p>
                  <v-btn
                    @click="sample_img_section = !sample_img_section"
                    style="margin: 10px"
                    v-show="!sample_img_section"
                    v-scroll-to="'#profile-img-section'"
                  >
                    サンプルから選択
                    <span>する</span>
                  </v-btn>
                  <div class="position-relative" style="height: 150px; width: 150px">
                    <v-img
                      style="height: 150px; width: 150px"
                      class="img-fluid rounded-circle"
                      onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
                      :src="this.thumbnail1"
                      fill
                      max-height="200"
                      v-if="selectedFile"
                    ></v-img>
                    <v-img
                      style="height: 150px; width: 150px"
                      class="img-fluid rounded-circle"
                      onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
                      :src="this.profile1"
                      fill
                      max-height="200"
                      v-else
                    ></v-img>
                    <IconDelete v-if="!isDefaultAvatar(this.profile1, 1)" @delete="deleteAvatar(1)" class="icon-delete"></IconDelete>
                  </div>

                  <v-file-input v-model="selectedFile" label="写真選択" accept="image/*" @change="handleFileSelect(selectedFile, 1)"></v-file-input>
                </v-col>

                <v-col cols="12" class="white-card">
                  <p style="font-weight: 900">プロフィール写真1</p>
                  <div class="position-relative" style="height: 150px; width: 150px">
                    <v-img
                      style="height: 150px; width: 150px"
                      class="img-fluid rounded-circle"
                      onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
                      :src="this.thumbnail2"
                      fill
                      max-height="200"
                      v-if="selectedFile2"
                    ></v-img>
                    <v-img
                      style="height: 150px; width: 150px"
                      class="img-fluid rounded-circle"
                      onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
                      :src="this.profile2"
                      fill
                      max-height="200"
                      v-else
                    ></v-img>
                    <IconDelete v-if="!isDefaultAvatar(this.profile2, 2)" @delete="deleteAvatar(2)" class="icon-delete"></IconDelete>
                  </div>
                  <v-file-input v-model="selectedFile2" label="写真選択" accept="image/*" @change="handleFileSelect(selectedFile2, 2)"></v-file-input>

                  <v-btn @click="sample_img_section = !sample_img_section" style="margin: 10px" v-show="sample_img_section">
                    サンプルから選択
                    <span>しない</span>
                  </v-btn>
                </v-col>

                <v-col cols="12" class="white-card">
                  <p style="font-weight: 900">プロフィール写真2</p>
                  <div class="position-relative" style="height: 150px; width: 150px">
                    <v-img
                      style="height: 150px; width: 150px"
                      class="img-fluid rounded-circle"
                      onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
                      :src="this.thumbnail3"
                      fill
                      max-height="200"
                      v-if="selectedFile3"
                    ></v-img>
                    <v-img
                      style="height: 150px; width: 150px"
                      class="img-fluid rounded-circle"
                      onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
                      :src="this.profile3"
                      fill
                      max-height="200"
                      v-else
                    ></v-img>
                    <IconDelete v-if="!isDefaultAvatar(this.profile3, 3)" @delete="deleteAvatar(3)" class="icon-delete"></IconDelete>
                  </div>
                  <v-file-input v-model="selectedFile3" label="写真選択" accept="image/*" @change="handleFileSelect(selectedFile3, 3)"></v-file-input>
                </v-col>
              </v-row>
            </v-container>

            <v-container v-show="sample_img_section" id="sample-img-section">
              <v-row>
                <v-col cols="6" sm="6" v-for="i in 5" :key="i">
                  <v-img
                    style="height: 150px; width: 150px; margin: 3px"
                    class="img-fluid rounded-circle"
                    :src="'https://matching-prod-backet.s3.ap-northeast-1.amazonaws.com/default_avaters/0' + i + '.jpg'"
                    fill
                    max-height="200"
                    @click="default_img_no = i"
                    :style="{
                      border: 'solid 2px #7553DF',
                      'border-radius': '10px',
                    }"
                    v-if="default_img_no == i"
                  ></v-img>
                  <v-img
                    style="height: 150px; width: 150px; margin: 3px"
                    class="img-fluid rounded-circle"
                    :src="'https://matching-prod-backet.s3.ap-northeast-1.amazonaws.com/default_avaters/0' + i + '.jpg'"
                    fill
                    max-height="200"
                    @click="default_img_no = i"
                    v-else
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <div class="form-group">
            <label for="form_twitter_name">
              マッチンユーザ名
              <span style="color: red; font-size: 0.7rem">必須</span>
            </label>
            <input v-model="form.twitter_name" type="text" class="form-control" id="form_twitter_name" placeholder="マッチンユーザ名" required />
          </div>

          <div class="form-group">
            <label for="form_twitterid">TwitterのID</label>
            <p style="color: red" v-if="false">TwitterIDの入力形式を確認してください</p>

            <input v-model="form.twitterid" type="text" class="form-control" id="form_twitterid" placeholder="Twitter ID" required />
          </div>

          <div class="form-group">
            <label for="form_tiktok">Tiktok</label>
            <input v-model="form.tiktok" type="text" class="form-control" id="form_tiktok" placeholder="Tiktok" required />
          </div>

          <div class="form-group">
            <label for="form_instagram">Instagram</label>
            <input v-model="form.instagram" type="text" class="form-control" id="form_instagram" placeholder="instagram" required />
          </div>

          <div class="form-group">
            <label for="form_age">
              年齢
              <span style="color: red; font-size: 0.7rem">必須</span>
            </label>
            <select v-model="form.age" class="form-control" required="required" name="form_age" id="form_age">
              <option value="">選択してください</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
              <option value="51">51</option>
              <option value="52">52</option>
              <option value="53">53</option>
              <option value="54">54</option>
              <option value="55">55</option>
              <option value="56">56</option>
              <option value="57">57</option>
              <option value="58">58</option>
              <option value="59">59</option>
              <option value="60">60</option>
              <option value="61">61</option>
              <option value="62">62</option>
              <option value="63">63</option>
              <option value="64">64</option>
              <option value="65">65</option>
              <option value="66">66</option>
              <option value="67">67</option>
              <option value="68">68</option>
              <option value="69">69</option>
              <option value="70">70</option>
              <option value="71">71</option>
              <option value="72">72</option>
              <option value="73">73</option>
              <option value="74">74</option>
              <option value="75">75</option>
              <option value="76">76</option>
              <option value="77">77</option>
              <option value="78">78</option>
              <option value="79">79</option>
              <option value="80">80</option>
              <option value="81">81</option>
              <option value="82">82</option>
              <option value="83">83</option>
              <option value="84">84</option>
              <option value="85">85</option>
            </select>
          </div>
          <label for="user_maleraw_attributes_貴方の活動範囲（会える都道府県）を教えて下さい。(最大10個まで)">
            貴方の活動範囲(最大10個まで)
            <span style="color: red; font-size: 0.7rem">必須</span>
          </label>

          <div class="white-card" v-for="m in regions.length" :key="regions[m]" style="padding-top: 0px; padding-bottom: 0px">
            <v-row style="margin: 0px 1px 0px 1px">
              <v-col cols="3" class="d-flex align-center" style="background: #7553df; border-top-left-radius: 5px; border-bottom-left-radius: 5px">
                <p style="margin: auto; color: white; font-weight: 700">
                  {{ regions_jp[m - 1] }}
                </p>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-checkbox
                    style="font-size: 10px"
                    cols="3"
                    v-for="n in area_count[regions[m - 1]]"
                    :key="area_list[n - 1 + area_count_dif[regions[m - 1]]]"
                    v-model="form.area"
                    :label="area_list[n - 1 + area_count_dif[regions[m - 1]]]"
                    :value="area_list[n - 1 + area_count_dif[regions[m - 1]]]"
                    :disabled="form.area.length > 9 && !form.area.includes(area_list[n - 1 + area_count_dif[regions[m - 1]]])"
                  ></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <label>あなたの体型</label>
          <div class="white-card">
            <v-radio-group v-model="form.body_type" required>
              <v-radio label="痩せ型" value="痩せ型"></v-radio>
              <v-radio label="普通" value="普通"></v-radio>
              <v-radio label="むっちり" value="むっちり"></v-radio>
              <v-radio label="ぽっちゃり" value="ぽっちゃり"></v-radio>
              <v-radio label="かなりぽっちゃり" value="かなりぽっちゃり"></v-radio>
            </v-radio-group>
          </div>
          <label>
            貴女の理想のエッチ(50文字以上)
            <span v-if="form.sexual_experience.replace(/\s+/g, '').length < 50" style="color: red">
              {{ form.sexual_experience.replace(/\s+/g, '').length }}文字 50文字以上入力してください
            </span>
            <span v-else>{{ form.sexual_experience.replace(/\s+/g, '').length }}文字</span>
            <span style="color: red" v-if="form.sexual_experience.match(/(.)\1{10,1000}/)">
              繰り返し文字の入力はマッチングの精度が下がる為、変更してください。
            </span>
          </label>
          <div class="white-card">
            <v-textarea v-model="form.sexual_experience" required></v-textarea>
          </div>

          <label>動画撮影</label>
          <div class="white-card">
            <v-radio-group column v-model="form.takemovie" required>
              <v-radio label="OKです！（顔出しNG)" value="OKです！（顔出しNG)"></v-radio>
              <v-radio label="基本、OKです！（相手による)" value="基本、OKです！（相手による)"></v-radio>
              <v-radio label="NGです！" value="NGです！"></v-radio>
            </v-radio-group>
          </div>

          <label>都合のつきやすいタイミング</label>
          <div class="white-card">
            <v-row style="padding: 10px">
              <v-checkbox v-model="form.your_freetimes" label="平日の昼間" value="平日の昼間"></v-checkbox>
              <v-checkbox v-model="form.your_freetimes" label="平日の夜" value="平日の夜"></v-checkbox>
              <v-checkbox v-model="form.your_freetimes" label="土日祝日の昼間" value="土日祝日の昼間"></v-checkbox>
              <v-checkbox v-model="form.your_freetimes" label="土日祝日の夜" value="土日祝日の夜"></v-checkbox>
            </v-row>
          </div>

          <label>あなたの許容範囲</label>
          <div class="white-card">
            <v-radio-group v-model="form.your_pre_dm" label="DM" required>
              <v-radio label="1日にたくさんしたい" value="1日にたくさんしたい"></v-radio>
              <v-radio label="1日に数回はやりとりしたい" value="1日に数回はやりとりしたい"></v-radio>
              <v-radio label="相手に合わせる" value="相手に合わせる"></v-radio>
              <v-radio label="あまり返せない" value="あまり返せない"></v-radio>
            </v-radio-group>
          </div>

          <div class="white-card">
            <v-radio-group v-model="form.your_pre_tel" label="会う前の電話" required>
              <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
              <v-radio label="できればしたい" value="できればしたい"></v-radio>
              <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
              <v-radio label="したくない" value="したくない"></v-radio>
            </v-radio-group>
          </div>

          <div class="white-card">
            <v-radio-group v-model="form.your_pre_photo" label="会う前の写真交換" required>
              <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
              <v-radio label="できればしたい" value="できればしたい"></v-radio>
              <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
              <v-radio label="したくない" value="したくない"></v-radio>
            </v-radio-group>
          </div>

          <div class="white-card">
            <v-radio-group v-model="form.your_pre_contact" label="連絡先交換" required>
              <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
              <v-radio label="できればしたい" value="できればしたい"></v-radio>
              <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
              <v-radio label="したくない" value="したくない"></v-radio>
            </v-radio-group>
          </div>
          <div style="margin-top: 25px; text-align: center">
            <span style="font-size: 0.7rem; color: red">画像の無断利用/無断転載は禁止です。</span>
          </div>
          <div v-for="m in form_columns.length" :key="m">
            <label>{{ form_columns_jp[m - 1] }}</label>
            <div class="white-card">
              <v-container>
                <v-row dense fill-height>
                  <v-col cols="6" sm="6" md="3" lg="3" xl="3" class="pl-5">
                    <div
                      style="width: 100%; max-height: 400px; border-radius: 10px"
                      :style="[
                        {
                          'background-image': 'url(' + require(`@/assets/form/${form_columns[m - 1]}.jpg`) + ')',
                          width: '100%',
                          'max-height': '400px',
                          'border-radius': '10px',
                          'min-height': '168px',
                          'background-size': 'cover',
                        },
                      ]"
                    ></div>
                  </v-col>
                  <v-col cols="6" sm="6" md="9" lg="9" xl="9">
                    <v-container class="px-2 py-0" fill-height>
                      <v-row class="justify-center align-content-space-between" fill-height style="vertical-align: middle; height: 100%">
                        <v-col class="pa-1 align-content-space-between q-box" cols="12" sm="12" md="6" lg="6" xl="6" v-for="n in 4" :key="'a' + n">
                          <v-card
                            flat
                            class="full-height pa-1 d-flex flex-column justify-center q-box-text"
                            color="#D95B89"
                            style="height: 100%; text-align: center; color: white"
                            v-if="form[form_columns[m - 1]] == form_text[form_columns[m - 1]][n - 1]"
                            @click="form.form_columns[m - 1] = form_text[form_columns[m - 1]][n - 1]"
                          >
                            {{ form_text[form_columns[m - 1]][n - 1] }}
                          </v-card>
                          <v-card
                            flat
                            class="full-height pa-1 d-flex flex-column justify-center q-box-text"
                            color="grey lighten-4"
                            style="height: 100%; text-align: center"
                            v-else
                            @click="form[form_columns[m - 1]] = form_text[form_columns[m - 1]][n - 1]"
                          >
                            {{ form_text[form_columns[m - 1]][n - 1] }}
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
          <div v-if="false">
            <div class="white-card">
              <v-radio-group v-model="form.your_blow_job" label="フェラ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_cunnilingus" label="クンニ" required>
                <v-radio label="積極的にやってほしい" value="積極的にやってほしい"></v-radio>
                <v-radio label="やってほしい" value="やってほしい"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やらなくていい" value="やらなくていい"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_choking" label="首しめ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_kousoku" label="拘束" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_kisuma" label="噛み跡" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_nouiki" label="催眠" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_anal_sex" label="アナル" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_word_attack_easy" label="優しい言葉ぜめ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_word_attack_hard" label="激しい言葉ぜめ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_spanking" label="スパンキング" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_wild" label="野外プレイ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_ejaculation" label="潮吹き" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_irrumatio" label="イラマチオ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_multi" label="複数人" required>
                <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
                <v-radio label="できればしたい" value="できればしたい"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="したくない" value="したくない"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_cosplay" label="コスプレ" required>
                <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
                <v-radio label="できればしたい" value="できればしたい"></v-radio>
                <v-radio label="男性が求めるなら！" value="男性が求めるなら！"></v-radio>
                <v-radio label="したくない" value="したくない"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_sextoy" label="おもちゃ" required>
                <v-radio label="挿入系＆振動系使ってほしい" value="挿入系＆振動系使ってほしい"></v-radio>
                <v-radio label="挿入系使ってほしい" value="挿入系使ってほしい"></v-radio>
                <v-radio label="振動系使ってほしい" value="振動系使ってほしい"></v-radio>
                <v-radio label="おもちゃは使いたくない" value="おもちゃは使いたくない"></v-radio>
              </v-radio-group>
            </div>
          </div>

          <label>貴方のS度（数字が大きい程ドS）</label>

          <div class="white-card">
            <v-radio-group v-model="form.your_sadism" required>
              <v-radio label="１，相手を攻めることや奉仕することは苦手" value="１，相手を攻めることや奉仕することは苦手"></v-radio>
              <v-radio
                label="２，奉仕することが嬉しい！楽しい！母性を感じちゃう！"
                value="２，奉仕することが嬉しい！楽しい！母性を感じちゃう！"
              ></v-radio>
              <v-radio
                label="３，強めにたくさん攻めることに楽しさや喜びを感じる"
                value="３，強めにたくさん攻めることに楽しさや喜びを感じる"
              ></v-radio>
              <v-radio
                label="４，射精管理、スパンキング等、激しいことをしたい"
                value="４，射精管理、スパンキング等、暴力的で激しいことをしたい"
              ></v-radio>
            </v-radio-group>
          </div>

          <label>貴方のM度（数字が大きい程ドM）</label>
          <div class="white-card">
            <v-radio-group v-model="form.your_masochism" required>
              <v-radio
                label="１，相手に”攻めてる感”出されるとイライラする、不安になる、怖い"
                value="１，相手に”攻めてる感”出されるとイライラする、不安になる、怖い"
              ></v-radio>
              <v-radio
                label="２，気持ちよくしてもらうことは好きだが”攻めてる感”を出されるのは苦手"
                value="２，気持ちよくしてもらうことは好きだが”攻めてる感”を出されるのは苦手"
              ></v-radio>
              <v-radio
                label="３，首絞め、言葉攻め、おもちゃ攻め、軽いスパンキングなどで攻めて欲しい"
                value="３，首絞め、言葉攻め、おもちゃ攻め、軽いスパンキングなどで攻めて欲しい"
              ></v-radio>
              <v-radio label="４，高圧的な言葉、行動に興奮を感じる" value="４，暴力的な言葉、行動に興奮を感じる"></v-radio>
            </v-radio-group>
          </div>

          <label>希望の相手の年齢</label>
          <div class="white-card">
            <v-row style="margin: 3px">
              <v-checkbox v-model="form.age_range" label="18~19歳" value="18~19歳"></v-checkbox>
              <v-checkbox v-model="form.age_range" label="20〜24歳" value="20〜24歳"></v-checkbox>
              <v-checkbox v-model="form.age_range" label="25〜30歳" value="25〜30歳"></v-checkbox>
              <v-checkbox v-model="form.age_range" label="31〜34歳" value="31〜34歳"></v-checkbox>
              <v-checkbox v-model="form.age_range" label="35歳〜39歳" value="35歳〜39歳"></v-checkbox>
              <v-checkbox v-model="form.age_range" label="40歳以上" value="40歳以上"></v-checkbox>
              <v-checkbox v-model="form.age_range" label="50歳以上" value="50歳以上"></v-checkbox>
            </v-row>
          </div>

          <label>
            あなたの長所(50文字以上)
            <span v-if="form.appear_points.replace(/\s+/g, '').length < 50" style="color: red">
              {{ form.appear_points.replace(/\s+/g, '').length }}文字 50文字以上入力してください。
            </span>
            <span style="color: red" v-if="form.appear_points.match(/(.)\1{10,1000}/)">
              繰り返し文字の入力はマッチングの精度が下がる為、変更してください。
            </span>
            <span v-else>{{ form.appear_points.replace(/\s+/g, '').length }}文字</span>
          </label>

          <div class="white-card">
            <v-textarea v-model="form.appear_points" required></v-textarea>
          </div>

          <label>これまでtwitterで男性と会ったこと</label>
          <div class="white-card">
            <v-radio-group row v-model="form.partner_follower" required>
              <v-radio label="ある" value="ある"></v-radio>
              <v-radio label="ない" value="ない"></v-radio>
            </v-radio-group>
          </div>

          <div v-show="false">
            <label>プロフィールを更新する際、以下の項目を毎回チェックしてください</label>
            <label style="color: red; font-size: 1rem">
              <Icon name="exclamation-triangle" scale="1" />
              DMが解放されてない場合レコメンドすることができません。DMの解放をお願いします。
            </label>
            <div class="white-card">
              <v-radio-group row v-model="check.no1" required>
                <v-radio label="確認しました" value="確認しました"></v-radio>
              </v-radio-group>
            </div>

            <label style="color: red; font-size: 1rem">
              <Icon name="exclamation-triangle" scale="1" />
              ユーザー様へ向けた情報を配信しているので、必ず
              <a class="alertlink" href="https://twitter.com/yamada_hona" _target="blank">@yamada_hona</a>
              と
              <a v-if="form.twitter_account_no == 2" class="alertlink" href="https://twitter.com/honachan_2" _target="blank">＠honachan_2</a>
              <a v-if="form.twitter_account_no == 3" class="alertlink" href="https://twitter.com/honachan_3" _target="blank">＠honachan_3</a>
              <a v-if="form.twitter_account_no == 4" class="alertlink" href="https://twitter.com/honachan_4" _target="blank">＠honachan_4</a>
              をフォローしてください。
            </label>
            <div class="white-card">
              <v-radio-group row v-model="check.no2" required>
                <v-radio label="確認しました" value="確認しました"></v-radio>
              </v-radio-group>
            </div>
          </div>

          <v-btn fab absolute bottom right fixed color="primary" style="margin-bottom: 70px" v-show="is_postable" v-scroll-to="'#submit-btn'">
            <!-- プラスのアイコンにします。 -->
            スキップ
          </v-btn>

          <button v-if="is_postable" id="submit-btn" class="btn-comp login-btn" @click="post()" submit>更新</button>
          <button v-else class="btn-comp login-btn" style="background: gray" disabled>ユーザー名を入力してください。</button>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
  .container {
    max-width: 800px;
  }

  .alertlink {
    font-size: 1rem;
  }

  .q-box {
    height: 25%;
  }

  .q-box-text {
    font-size: 0.6rem;
  }

  @media (min-width: 768px) {
    .q-box {
      height: 50%;
    }

    .q-box-text {
      font-size: 1rem;
    }
  }

  .q-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
  }

  h2 {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 50px;
  }

  label {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 30px;
  }

  input {
    margin-top: 5px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }

  .v-input {
    margin-bottom: 0;
  }

  a {
    color: #4b4b4b;
  }

  a:hover {
    color: #4b4b4b;
  }

  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }

  .signin-btn {
    background: rgb(97, 34, 219);
    background: linear-gradient(90deg, rgba(97, 34, 219, 1) 0%, rgba(91, 103, 229, 1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text {
    font-size: 0.8rem;
    padding-top: 20px;
  }

  .get-account-information-text {
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }

  .v-input {
    margin-left: 15px;
  }

  .v-radio {
    margin-bottom: 10px;
  }

  .v-input__control {
    margin-right: 15px;
  }

  .icon-delete {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 52%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<script>
  import axios from '../../util/axios'
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'
  import Spinner from 'vue-simple-spinner'
  import 'material-design-icons'
  import IconDelete from '../components/icons/IconDelete.vue'

  export default {
    name: 'femaleform',
    components: {
      Icon,
      Spinner,
      IconDelete,
    },
    data: function () {
      return {
        uid: '',
        client: '',
        access_token: '',
        id: '',
        message: '',
        data_all: '',
        isLoading: true,
        base_api_url: process.env.VUE_APP_BASE_API_URL,
        profile1: null,
        profile2: null,
        profile3: null,
        thumbnail1: null,
        thumbnail2: null,
        thumbnail3: null,
        selectedFile: null,
        selectedFile2: null,
        selectedFile3: null,
        sexual_experience_list: [
          {
            label: '0人',
            value: '0人',
          },
          {
            label: '1人',
            value: '1人',
          },
          {
            label: '2人',
            value: '2人',
          },
          {
            label: '3人',
            value: '3人',
          },
          {
            label: '4人',
            value: '4人',
          },
          {
            label: '5人',
            value: '5人',
          },
          {
            label: '6人',
            value: '6人',
          },
          {
            label: '7人',
            value: '7人',
          },
          {
            label: '8人',
            value: '8人',
          },
          {
            label: '9人',
            value: '9人',
          },
          {
            label: '10人',
            value: '10人',
          },
          {
            label: '11〜20人',
            value: '11〜20人',
          },
          {
            label: '21〜30人',
            value: '21〜30人',
          },
          {
            label: '31〜50人',
            value: '31〜50人',
          },
          {
            label: '51〜100人',
            value: '51〜100人',
          },
          {
            label: '101人以上',
            value: '101人以上',
          },
        ],
        form_columns: [
          'your_blow_job',
          'your_cunnilingus',
          'your_choking',
          'your_kousoku',
          'your_kisuma',
          'your_nouiki',
          'your_anal_sex',
          'your_word_attack_easy',
          'your_word_attack_hard',
          'your_spanking',
          'your_wild',
          'your_ejaculation',
          'your_irrumatio',
          'your_multi',
          'your_cosplay',
          'your_sextoy',
        ],
        form_columns_jp: [
          'フェラ',
          'クンニ',
          '首しめ',
          '拘束',
          '噛み跡',
          '脳イキ',
          'アナル',
          '優しい言葉責め',
          '激しい言葉責め',
          'スパンキング',
          '野外プレイ',
          '潮吹き',
          'イラマチオ',
          '複数人',
          'コスプレ',
          'おもちゃ',
        ],
        form_text: {
          your_blow_job: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_cunnilingus: ['積極的にやってほしい', 'やってほしい', '男性が求めるなら！', 'やらなくていい'],
          your_choking: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_kousoku: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_kisuma: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_nouiki: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_anal_sex: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_word_attack_easy: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_word_attack_hard: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_spanking: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_wild: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_ejaculation: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_irrumatio: ['積極的にやりたい', 'やりたい（興味がある）', '男性が求めるなら！', 'やりたくない（できない）'],
          your_multi: ['絶対にしたい', 'できればしたい', '男性が求めるなら！', 'したくない'],
          your_cosplay: ['絶対にしたい', 'できればしたい', '男性が求めるなら！', 'したくない'],
          your_sextoy: ['挿入系＆振動系使ってほしい', '挿入系使ってほしい', '振動系使ってほしい', 'おもちゃは使いたくない'],
        },
        area_list: [
          '北海道（札幌）',
          '北海道（道東）',
          '北海道（道南）',
          '北海道（道北）',
          '北海道（道央）',
          '青森県',
          '岩手県',
          '秋田県',
          '山形県',
          '宮城県',
          '福島県',
          '栃木県',
          '茨城県',
          '群馬県',
          '千葉県',
          '埼玉県',
          '東京都',
          '神奈川県',
          '山梨県',
          '新潟県',
          '長野県',
          '富山県',
          '石川県',
          '福井県',
          '静岡県',
          '愛知県',
          '岐阜県',
          '三重県',
          '滋賀県',
          '奈良県',
          '京都府',
          '大阪府',
          '和歌山県',
          '兵庫県',
          '岡山県',
          '広島県',
          '鳥取県',
          '島根県',
          '山口県',
          '香川県',
          '徳島県',
          '高知県',
          '愛媛県',
          '福岡県',
          '長崎県',
          '佐賀県',
          '大分県',
          '熊本県',
          '宮崎県',
          '鹿児島県',
          '沖縄県',
        ],
        regions: ['hokkaido', 'tohoku', 'kanto', 'chubu', 'kansai', 'chugoku', 'sikoku', 'kyushu'],
        regions_jp: ['北海道', '東北', '関東', '中部', '関西', '中国', '四国', '九州'],
        area_count: {
          hokkaido: 5,
          tohoku: 6,
          kanto: 7,
          chubu: 9,
          kansai: 7,
          chugoku: 5,
          sikoku: 4,
          kyushu: 8,
        },
        area_count_dif: {
          hokkaido: 0,
          tohoku: 5,
          kanto: 11,
          chubu: 18,
          kansai: 27,
          chugoku: 34,
          sikoku: 39,
          kyushu: 43,
        },
        freetimes_list: ['平日の昼間', '平日の夜', '土日祝日の昼間', '土日祝日の夜'],
        appear_points_list: [
          'いちゃいちゃ',
          'キス',
          '手マン',
          'クンニ',
          'ピストン運動',
          '拘束プレイ',
          '中イキ開発',
          '催眠',
          'アソコの大きさ',
          'その他',
        ],
        age_range_list: ['20代', '30代前半まで', '30代後半まで', '40代前半まで', '40代後半まで', '50代以上もいける'],
        check: {
          no1: null,
          no2: null,
        },
        form: {
          twitter_account_no: '',
          phone: '',
          twitterid: '',
          twitter_name: '',
          tiktok: '',
          instagram: '',
          age: '',
          area: [],
          body_type: '',
          height: '',
          weight: '',
          personarity: '',
          takemovie: '',
          sexual_experience: '',
          your_pre_dm: '',
          your_pre_tel: '',
          your_pre_photo: '',
          your_pre_contact: '',
          your_blow_job: '',
          your_cunnilingus: '',
          your_choking: '',
          your_kousoku: '',
          your_kisuma: '',
          your_nouiki: '',
          your_anal_sex: '',
          your_word_attack_easy: '',
          your_word_attack_hard: '',
          your_spanking: '',
          your_wild: '',
          your_ejaculation: '',
          your_irrumatio: '',
          your_multi: '',
          your_sadism: '',
          your_masochism: '',
          your_freetimes: [],
          your_sextoy: '',
          your_cosplay: '',
          age_range: [],
          appear_points: '',
          propensity: '',
          partner_follower: '',
          body_type_range: '',
        },
        sample_img_section: false,
        default_img_no: 0,
      }
    },
    mounted() {
      this.getAccountData()
      if (this.uid == null) {
        this.$router.push('/login')
      }
    },
    computed: {
      is_postable() {
        if (
          // (this.form.twitterid!=null)&&
          // (this.form.twitterid!='')&&
          // (this.form.twitterid.match(/^[0-9a-zA-Z_]{1,15}$/))&&
          this.form.twitter_name != null &&
          this.form.twitter_name != '' &&
          this.form.age != null &&
          this.form.age != '' &&
          this.form.area != null &&
          this.form.area != ''
          // (this.form.body_type!=null)&&
          // (this.form.body_type!='')&&
          // (this.form.sexual_experience!=null)&&
          // (this.form.sexual_experience.replace(/\s+/g, "")!='')&&
          // (this.form.sexual_experience.replace(/\s+/g, "").length > 49)&&
          // (this.form.takemovie!=null)&&
          // (this.form.takemovie!='')&&
          // (this.form.your_pre_dm!=null)&&
          // (this.form.your_pre_dm!='')&&
          // (this.form.your_pre_tel!=null)&&
          // (this.form.your_pre_tel!='')&&
          // (this.form.your_pre_photo!=null)&&
          // (this.form.your_pre_photo!='')&&
          // (this.form.your_pre_contact!=null)&&
          // (this.form.your_pre_contact!='')&&
          // (this.form.your_blow_job!=null)&&
          // (this.form.your_blow_job!='')&&
          // (this.form.your_cunnilingus!=null)&&
          // (this.form.your_cunnilingus!='')&&
          // (this.form.your_choking!=null)&&
          // (this.form.your_choking!='')&&
          // (this.form.your_kousoku!=null)&&
          // (this.form.your_kousoku!='')&&
          // (this.form.your_kisuma!=null)&&
          // (this.form.your_kisuma!='')&&
          // (this.form.your_nouiki!=null)&&
          // (this.form.your_nouiki!='')&&
          // (this.form.your_anal_sex!=null)&&
          // (this.form.your_anal_sex!='')&&
          // (this.form.your_word_attack_easy!=null)&&
          // (this.form.your_word_attack_easy!='')&&
          // (this.form.your_word_attack_hard!=null)&&
          // (this.form.your_word_attack_hard!='')&&
          // (this.form.your_spanking!=null)&&
          // (this.form.your_spanking!='')&&
          // (this.form.your_wild!=null)&&
          // (this.form.your_wild!='')&&
          // (this.form.your_ejaculation!=null)&&
          // (this.form.your_ejaculation!='')&&
          // (this.form.your_irrumatio!=null)&&
          // (this.form.your_irrumatio!='')&&
          // (this.form.your_multi!=null)&&
          // (this.form.your_multi!='')&&
          // (this.form.your_sadism!=null)&&
          // (this.form.your_sadism!='')&&
          // (this.form.your_masochism!=null)&&
          // (this.form.your_masochism!='')&&

          // (this.form.your_freetimes!=null)&&
          // (this.form.your_freetimes!='')&&
          // (this.form.your_sextoy!=null)&&
          // (this.form.your_sextoy!='')&&
          // (this.form.your_cosplay!=null)&&
          // (this.form.your_cosplay!='')&&

          // (this.form.age_range!=null)&&
          // (this.form.age_range!='')&&
          // (this.form.appear_points!=null)&&
          // (this.form.appear_points.replace(/\s+/g, "")!='')&&
          // (this.form.appear_points.replace(/\s+/g, "").length > 49)&&
          // (this.form.partner_follower!=null)&&
          // (this.form.partner_follower!='')&&
          // (this.check.no1!=null)&&
          // (this.check.no1!='')&&
          // (this.check.no2!=null)&&
          // (this.check.no2!='')&&
          // (!this.form.appear_points.match(/(.)\1{10,1000}/))&&
          // (!this.form.sexual_experience.match(/(.)\1{10,1000}/))
        ) {
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      handleFileSelect(file, index) {
        if (file) {
          // FileReaderオブジェクトを作成
          const reader = new FileReader()

          // ファイルが読み込まれた後の処理を定義
          reader.onload = (event) => {
            // サムネイル用のデータURLを取得
            if (index == 1) {
              this.thumbnail1 = event.target.result
              this.form.profile1 = event.target.files[0]
            } else if (index == 2) {
              this.thumbnail2 = event.target.result
              this.form.profile2 = event.target.files[0]
            } else if (index == 3) {
              this.thumbnail3 = event.target.result
              this.form.profile3 = event.target.files[0]
            }
          }

          // ファイルをData URL形式で読み込む
          reader.readAsDataURL(file)
        }
      },

      async uploadFile() {
        if (this.selectedFile || this.selectedFile2 || this.selectedFile3) {
          const formData = new FormData()
          if (this.selectedFile != null) {
            formData.append('profile1', this.selectedFile)
          }
          if (this.selectedFile2 != null) {
            formData.append('profile2', this.selectedFile2)
          }
          if (this.selectedFile3 != null) {
            formData.append('profile3', this.selectedFile3)
          }
          await axios
            .post('/api/user/profile_img', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
            })
            .then((response) => {
              // アップロード成功時の処理
              console.log(response.data)
            })
            .catch((error) => {
              // エラー処理
              console.error(error)
            })
        }
      },
      async getAccountData() {
        this.uid = localStorage.getItem('uid')
        this.client = localStorage.getItem('client')
        this.access_token = localStorage.getItem('access-token')
        if (this.uid) {
          const result = await axios
            .get('/api/account-detail', {
              headers: {
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
            })
            .catch((e) => {
              console.error(e)
            })

          if (!result) {
            // エラーの場合ログイン画面へ遷移させる
            this.message = 'エラーが発生しました。'
            return
          }

          if (result.data.state) {
            this.id = result.data.id
            this.data_all = result.data
            this.profile1 = result.data.profile1
            this.profile2 = result.data.profile2
            this.profile3 = result.data.profile3
            this.form.twitter_account_no = result.data.twitter_account_no
            this.form.phone = result.data.phone
            this.form.twitterid = result.data.twitterid
            this.form.tiktok = result.data.tiktok
            this.form.instagram = result.data.instagram
            this.form.twitter_name = result.data.twitter_name
            this.form.age = result.data.age
            this.form.area = result.data.area
            this.form.body_type = result.data.body_type
            this.form.height = result.data.height
            this.form.weight = result.data.weight
            this.form.personarity = result.data.personarity
            this.form.takemovie = result.data.takemovie
            this.form.sexual_experience = !result.data.sexual_experience ? '' : result.data.sexual_experience
            this.form.your_pre_dm = result.data.your_pre_dm
            this.form.your_pre_tel = result.data.your_pre_tel
            this.form.your_pre_photo = result.data.your_pre_photo
            this.form.your_pre_contact = result.data.your_pre_contact
            this.form.your_blow_job = result.data.your_blow_job
            this.form.your_cunnilingus = result.data.your_cunnilingus
            this.form.your_choking = result.data.your_choking
            this.form.your_kousoku = result.data.your_kousoku
            this.form.your_kisuma = result.data.your_kisuma
            this.form.your_nouiki = result.data.your_nouiki
            this.form.your_anal_sex = result.data.your_anal_sex
            this.form.your_word_attack_easy = result.data.your_word_attack_easy
            this.form.your_word_attack_hard = result.data.your_word_attack_hard
            this.form.your_spanking = result.data.your_spanking
            this.form.your_wild = result.data.your_wild
            this.form.your_ejaculation = result.data.your_ejaculation
            this.form.your_irrumatio = result.data.your_irrumatio
            this.form.your_multi = result.data.your_multi
            this.form.your_sadism = result.data.your_sadism
            this.form.your_masochism = result.data.your_masochism
            this.form.your_freetimes = result.data.your_freetimes
            this.form.your_sextoy = result.data.your_sextoy
            this.form.your_cosplay = result.data.your_cosplay
            this.form.age_range = result.data.age_range
            this.form.appear_points = !result.data.appear_points ? '' : result.data.appear_points
            this.form.propensity = result.data.propensity
            this.form.partner_follower = result.data.partner_follower
            this.form.body_type_range = result.data.body_type_range
            this.isLoading = false
          }
        }
      },
      async post() {
        this.uploadFile()
        if (!this.sample_img_section) {
          this.default_img_no = 0
        }
        const result = await axios({
          method: 'POST',
          url: '/api/user/update',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            user: {
              id: this.id,
              twitterid: this.form.twitterid,
              profile1: this.form.profile1,
              profile2: this.form.profile2,
              profile3: this.form.profile3,
              tiktok: this.form.tiktok,
              instagram: this.form.instagram,
              default_img_no: this.default_img_no,
              femaleraw_attributes: {
                twitter_name: this.form.twitter_name,
                age: this.form.age,
                area: this.form.area.join(', '),
                body_type: this.form.body_type,
                personarity: this.form.personarity,
                takemovie: this.form.takemovie,
                sexual_experience: this.form.sexual_experience,
                your_pre_dm: this.form.your_pre_dm,
                your_pre_tel: this.form.your_pre_tel,
                your_pre_photo: this.form.your_pre_photo,
                your_pre_contact: this.form.your_pre_contact,
                your_blow_job: this.form.your_blow_job,
                your_cunnilingus: this.form.your_cunnilingus,
                your_choking: this.form.your_choking,
                your_kousoku: this.form.your_kousoku,
                your_kisuma: this.form.your_kisuma,
                your_nouiki: this.form.your_nouiki,
                your_anal_sex: this.form.your_anal_sex,
                your_word_attack_easy: this.form.your_word_attack_easy,
                your_word_attack_hard: this.form.your_word_attack_hard,
                your_spanking: this.form.your_spanking,
                your_wild: this.form.your_wild,
                your_ejaculation: this.form.your_ejaculation,
                your_irrumatio: this.form.your_irrumatio,
                your_multi: this.form.your_multi,
                your_sadism: this.form.your_sadism,
                your_masochism: this.form.your_masochism,
                your_freetimes: this.form.your_freetimes.join(', '),
                your_sextoy: this.form.your_sextoy,
                your_cosplay: this.form.your_cosplay,
                age_range: this.form.age_range.join(', '),
                appear_points: this.form.appear_points,
                propensity: this.form.propensity,
                partner_follower: this.form.partner_follower,
              },
            },
          },
        }).catch((e) => {
          e
        })

        if (!result.data.success) {
          this.message = '入力内容を確認してください'
          return
        }
        if (result.data.success) {
          this.post_content = ''
          this.getAccountData()
          this.$router.push({
            name: 'Home',
            query: {
              form_post: true,
            },
          })
          this.message = '更新しました'
        }
      },

      async deleteAvatar(profile_img_no) {
        console.log(profile_img_no)
        await axios({
          url: '/api/user/profile_img',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          method: 'DELETE',
          data: {
            profile_img_no,
          },
        }).then(() => {
          const defaultImg = '/images/fallback/default.png'
          switch (profile_img_no) {
            case 1:
              this.profile1 = defaultImg
              break
            case 2:
              this.profile2 = defaultImg
              break
            case 3:
              this.profile3 = defaultImg
          }
        })
      },

      isDefaultAvatar(path, no) {
        switch (no) {
          case 1:
            return this.thumbnail1 || path === '/images/fallback/default.png'
          case 2:
            return this.thumbnail2 || path === '/images/fallback/default.png'
          case 3:
            return this.thumbnail3 || path === '/images/fallback/default.png'
        }
      },
    },
  }
</script>
