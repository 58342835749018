<template>
  <div style="padding-top: 60px; padding-bottom: 60px">
    <v-item-group class="bg-">
      <v-container>
        <div class="no-item" v-if="users.length === 0">まだ足跡はありません。タイムラインを覗いて、まずは自分からも沢山足跡をつけよう💕</div>
        <v-item :key="user.id" v-for="user in users">
          <div class="d-flex align-center bg-white py-2 px-3 mb-1 flex-wrap">
            <router-link :to="user.can_not_liked
              ? { name: user.sex === 1 ? 'Profile' : 'ProfileFemale', query: { id: user.visitor_id } }
              : { name: 'subprofile', query: { uuid: user.visitor_uuid } }">
              <v-avatar size="50px" class="p-0" color="#ccc">
                <v-img alt="" :src="user.avatar_profile_1"></v-img>
              </v-avatar>
            </router-link>
            <div class="d-flex text-left flex-grow-1 align-items-center overflow-hidden min-w-0">
              <div class="d-flex flex-column px-2 flex-grow-1 overflow-hidden min-w-0">
                <router-link :to="user.can_not_liked
                  ? { name: user.sex === 1 ? 'Profile' : 'ProfileFemale', query: { id: user.visitor_id } }
                  : { name: 'subprofile', query: { uuid: user.visitor_uuid } }"
                  class="text-body-1 font-weight-bold text-truncate d-block">
                  {{ user.twitter_name }}
                </router-link>
                <a :href="'https://twitter.com/' + user.twitter_id" class="text-body-2 text-truncate d-block"
                  style="white-space: nowrap">@{{ user.twitter_id }}</a>
              </div>
              <div class=" flex-shrink-0 badge-sex" @click="sendLike(user)"
                :class="user.is_visitor_liked || user.can_not_liked ? 'disabled-cursor' : 'pointer-cursor'">
                <span
                  :class="user.is_visitor_liked || user.can_not_liked ? 'mdi mdi-heart badge-item disable' : 'mdi mdi-heart-outline badge-item'">
                </span>
              </div>
            </div>
          </div>
        </v-item>
        <v-dialog v-model="dialog_show" width="500" @input="handleClose">
          <div style="background: white; color: #222222; padding: 20px">
            <p class="matching-success">いいね👍</p>
            <p>いいねを送りました。</p>
          </div>
        </v-dialog>
        <v-pagination v-show="!!users && users.length > 0" v-model="page" :length="paginationTotal" circle
          :total-visible="7" class="m-0"></v-pagination>
      </v-container>
    </v-item-group>
  </div>
</template>

<style scoped>
.matching-count {
  font-size: 5rem;
  font-weight: 900;
  color: #f74481;
  font-family: Montserrat;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.badge-sex {
  width: 60px;
}

.badge-item {
  display: inline-block;
  color: #f8608d;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 52px;
  text-align: center;
}

.badge-item-male-color {
  background-color: #775dde;
}

.matching-success {
  font-size: 3rem;
  font-weight: 800;
  color: #ff8c00;
  background: -webkit-linear-gradient(0deg, #8350de, #df468b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

.pointer-cursor {
  cursor: pointer !important;
}

.no-item {
  background-color: #f0f0f0;
  padding: 10px;
  font-size: 20px;
}
</style>

<script>
import axios from '../../util/axios'
import 'material-design-icons'

export default {
  name: 'footsprints',
  data() {
    return {
      users: [],
      page: 1,
      paginationTotal: 0,
      client: '',
      access_token: '',
      dialog_show: false,
      like_remain: 0,
      isLoading: false,
    }
  },
  mounted() {
    this.uid = localStorage.getItem('uid');
    this.access_token = localStorage.getItem('access-token');
    this.client = localStorage.getItem('client');
    this.getFootPrints()
    this.getAccountData()
  },
  watch: {
    page: function () {
      this.getFootPrints()
    },
  },
  methods: {
    async handleClose() {
    },
    async getAccountData() {
      this.isLoading = true
      const result = await axios
        .get('/api/timeline', {
          headers: {
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
            'token-type': this.token_type,
          },
        })
        .catch((e) => {
          e
        })
      this.isLoading = false
      this.like_remain = result.data.like_remain
    },
    async getFootPrints() {
      try {
        const result = await axios({
          method: 'GET',
          url: `/api/user_footprints?page=${this.page}&per_page=10`,
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
          },
          params: {
            email: this.uid,
          },
        })
        this.users = result.data.data
        this.paginationTotal = Math.round(result.data.total / 10)
      } catch (error) {
        console.error('Error get footprints:', error)
      }
    },
    async sendLike(user) {
      if (!user.can_not_liked && !user.is_visitor_liked) {
        if (!this.like_remain || this.like_remain === 0) {
          return this.$router.push('/shop')
        }
        const res = await axios({
          method: 'POST',
          url: '/api/send_like',
          uuid: user.visitor_uuid,

          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            uuid: user.visitor_uuid
          }
        }).catch((e) => {
          console.log("API Response:", e);
        });

        if (res.data.success) {
          this.dialog_show = !this.dialog_show;
          await this.getFootPrints();
        }
      }
    },
  },
}
</script>
