<template>
  <v-app>
    <div v-if="isLoading" style="padding-top: 60px">
      <Spinner></Spinner>
    </div>
    <div class="profile container" v-else>
      <transition name="bounce">
        <div class="flash" v-show="message_flag">
          <span>
            <p v-show="message_flag">{{ message }}</p>
          </span>
        </div>
      </transition>

      <carousel :autoplay="false" :loop="false" :per-page="1">
        <slide>
          <div class="slider-inner">
            <img class="user-thumbnail" style="max-width: 390px" :src="profile.profile1"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';" />
          </div>
        </slide>
        <slide v-if="profile.profile2 != '/images/fallback/default.png'">
          <div class="slider-inner">
            <img class="user-thumbnail" style="max-width: 390px" :src="profile.profile2"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';" />
          </div>
        </slide>
        <slide v-if="profile.profile3 != '/images/fallback/default.png'">
          <div class="slider-inner">
            <img class="user-thumbnail" style="max-width: 390px" :src="profile.profile3"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';" />
          </div>
        </slide>
      </carousel>
      <div class="profile-area">
        <div class="name-area" v-show="this.rec.status_n != 9">
          <p class="name">{{ profile.twitter_name }}</p>
        </div>
        <v-dialog v-model="matchingDialog" width="500">
          <div style="background: white; color: #222222; padding: 20px">
            <transition name="expand">
              <Icon name="heart" scale="5" style="" class="heart-icon" />
            </transition>
            <p class="matching-success">マッチン成立</p>
            <p>マッチンおめでとうございます！</p>
          </div>
        </v-dialog>
        <v-dialog v-model="noMatchingDialog" width="500">
          <div style="background: white; color: #222222; padding: 20px">
            <transition name="expand">
              <Icon name="heart-broken" scale="5" style="" class="heart-broken-icon" />
            </transition>
            <p class="matching-success">ノーマッチン</p>
            <p>ご回答ありがとうございました。</p>
            <p>次の男性を探しますね！</p>
            <button class="btn-comp copy-btn" @click="back()">戻る</button>
          </div>
        </v-dialog>
      </div>
      <div class="row">
        <div class="col-md-4" v-if="false"></div>
        <div class="col-md-4" v-if="rec.status_n != 8 && rec.status_n != 0 && rec.status_n != 9">
          <router-link :to="{ name: 'chat', query: { uuid: this.profile.uuid } }">
            <button class="btn-comp login-btn" style="margin-top: 0 !important">チャットする</button>
          </router-link>
        </div>
        <div class="col-md-4">
          <router-link :to="{ name: 'ReviewForm', query: { id: this.rec.id } }">
            <button class="btn-comp login-btn" style="margin-top: 0 !important"
              v-show="rec.status_n == 1 || rec.status_n == 2 || rec.status_n == 7 || showReviewButton">
              レビューを書く
            </button>
          </router-link>
        </div>
        <div class="col-md-4">
          <button @click="$router.back()" class="btn-comp login-btn" style="margin-top: 0 !important"
            v-show="rec.status_n == 1 || rec.status_n == 2 || rec.status_n == 7 || showReviewButton">
            戻る
          </button>
        </div>
      </div>
      <div class="row" v-show="this.rec.status_n != 9"></div>
      <!-- まっちんorノーマッチン -->
      <div
        v-if="rec.status_n == 0 || rec.status_n == 6 || rec.status_n == 8 || ng_ok == 'suspend' || (payment_confirmation == 1 && rec.status_n == 3)">
        <Transition>
          <div class="row" style="justify-content: space-around; padding-top: 15px; padding-bottom: 45px"
            v-show="!showNGReason || ng_ok == 'suspend'">
            <div class="col-12">
              <p v-if="rec.status_n == 0" style="font-weight: 900">マッチン、ノーマッチンを選んでください。</p>
              <p v-else style="font-weight: 900">マッチン、ノーマッチンを選んでください。</p>
            </div>
            <div class="col-6">
              <button class="btn-comp signin-btn" style="font-size: 1rem; background: #db668e; width: 100%" @click="
                showNGReason = !showNGReason
              ng_ok = 'ok'
              submitMatchingStatus('ok', 'ok')
              matchingDialog = !matchingDialog
                ">
                男性に連絡する
              </button>
            </div>
            <div class="col-6">
              <button style="font-size: 1rem; width: 100%" class="btn-comp signin-btn" @click="
                showNGReason = !showNGReason
              ng_ok = 'ng'
                ">
                男性に連絡しない
              </button>
            </div>
          </div>
        </Transition>

        <Transition>
          <div class="white-card" v-show="showNGReason && ng_ok == 'ng'" style="margin-top: 10px">
            <label>ノーマッチン理由を教えてください。</label>
            <v-radio-group row v-model="ngReason" label="" required>
              <v-radio label="NGなタイプ（雰囲気、好み）" value="タイプ（雰囲気、好み）" style="width: 100%; padding-bottom: 5px"></v-radio>
              <v-radio label="NGな体型（身長、体重）" value="体型（身長、体重）" style="width: 100%; padding-bottom: 5px"></v-radio>
              <v-radio label="アカウントが無理（ハメ撮りがある、エッチなツイートがない、フォロワー多い少ない、雰囲気写真がない）"
                value="アカウントが無理（ハメ撮りがある、エッチなツイートがない、フォロワー多い少ない、雰囲気写真がない）"
                style="width: 100%; padding-bottom: 5px"></v-radio>
              <v-radio label="性癖が合わない（アピールポイントがずれている）" value="性癖が合わない（アピールポイントがずれている）"
                style="width: 100%; padding-bottom: 5px"></v-radio>
              <v-radio label="その他" value="その他" style="width: 100%; padding-bottom: 5px"></v-radio>
            </v-radio-group>
            <transition>
              <v-container>
                <v-text-field v-model="ng_reason_other" style="margin: 10px" v-show="ngReason == 'その他'"
                  label="その他の理由(50文字以下)" :rule="textRules"></v-text-field>
              </v-container>
            </transition>
            <transition>
              <v-container>
                <v-row style="padding: 10px" v-show="ngReason == '体型（身長、体重）'">
                  <v-checkbox v-for="n in bodytype_list.length" :key="bodytype_list[n - 1]" row
                    v-model="ng_reason_bodytype" :label="bodytype_list[n - 1]"
                    :value="bodytype_list[n - 1]"></v-checkbox>
                  <v-select name="limit_height" v-model="ng_reason_limit_height" v-bind:items="limit_height_list"
                    item-text="label" item-value="value" label="身長下限"></v-select>
                </v-row>
              </v-container>
            </transition>
            <div class="col-12" v-show="is_postable">
              <button class="btn-comp signin-btn" @click="
                submitMatchingStatus('ng', 'with_reason')
              showNGReason = !showNGReason
              noMatchingDialog = !noMatchingDialog
              ng_ok = 'ng'
              rec.status_n = 3
                ">
                提出
              </button>
            </div>
          </div>
        </Transition>
      </div>
      <!-- まっちんorノーマッチン -->
      <div v-show="(rec.is_choosable || ng_ok == 'ok' || rec.status_n == 1) && !showReviewButton && false">
        <Transition>
          <div class="row" style="padding-top: 15px; padding-bottom: 45px"
            v-show="showReply || (rec.status_n == 1 && false)">
            <div class="col-12">
              <p style="font-weight: 900">男性からの返信あり or 返信なしを選んでください。</p>
            </div>
            <div class="col-6">
              <button class="btn-comp signin-btn" style="font-size: 1rem; background: #db668e; width: 100%" @click="
                showReply = !showReply
              submitReply('ok')
              showReviewButton = !showReviewButton
                ">
                返信あり
              </button>
            </div>
            <div class="col-6">
              <span v-if="!rec.is_choosable">
                <button style="background: gray; font-size: 1rem; width: 100%" class="btn-comp signin-btn" @click="
                  showReply = !showReply
                submitReply('ng')
                noReply = true
                  " :disabled="!rec.is_choosable">
                  返信なし
                </button>
                <p>男性に連絡するを選択した後24時間後から選択できます。</p>
              </span>
              <span v-else>
                <button style="font-size: 1rem; width: 100%" class="btn-comp signin-btn" @click="
                  showReply = !showReply
                submitReply('ng')
                noReply = true
                  " :disabled="!rec.is_choosable">
                  返信なし
                </button>
              </span>
            </div>
          </div>
        </Transition>
      </div>
      <div class="form-contents-area">
        <div class="white-card" v-show="this.rec.status_n != 9">
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>twitter</p>
            </div>
            <div class="content">
              <p v-if="profile.twitterid == null || profile.twitterid == ''">
                {{ profile.twitterid }}
              </p>
              <a :href="'https://twitter.com/' + profile.twitterid" target="_blank" v-else>{{ profile.twitterid }}</a>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>instagram</p>
            </div>
            <div class="content">
              <p v-if="profile.instagram == null || profile.instagram == ''">
                {{ profile.instagram }}
              </p>
              <a :href="'https://www.instagram.com/' + profile.instagram" target="_blank" v-else>{{ profile.instagram
                }}</a>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>tiktok</p>
            </div>
            <div class="content">
              <p v-if="profile.tiktok == null || profile.tiktok == ''">
                {{ profile.tiktok }}
              </p>
              <a :href="'https://www.tiktok.com/@' + profile.tiktok" target="_blank" v-else>{{ profile.tiktok }}</a>
            </div>
          </div>
        </div>
        <div class="white-card">
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>年齢</p>
            </div>
            <div class="content">
              <p>{{ profile.age }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>地域</p>
            </div>
            <div class="content">
              <p v-for="area in profile.area" :key="area">{{ area }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>身長</p>
            </div>
            <div class="content">
              <p>{{ profile.height }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>体重</p>
            </div>
            <div class="content">
              <p>{{ profile.weight }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>体型</p>
            </div>
            <div class="content">
              <p>{{ profile.body_type }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="form-contents-area">
        <div class="white-card">
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>撮影</p>
            </div>
            <div class="content">
              <p>{{ profile.takemovie }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>DM</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_dm }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>会う前の電話</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_tel }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>会う前の写真交換</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_photo }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>連絡先交換</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_contact }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>
                都合のつきやすい
                <br />
                タイミング
              </p>
            </div>
            <div class="content">
              <p>{{ profile.your_freetimes }}</p>
            </div>
          </div>
        </div>
        <div class="white-card">
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>アピールポイント</p>
            </div>
            <div class="content">
              <p v-for="ap in profile.appear_points" :key="ap">{{ ap }}</p>
            </div>
          </div>

          <div class="cont-wrapper">
            <div class="cont-title">
              <p>フェラ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_blow_job }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>クンニ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_cunnilingus }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>首しめ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_choking }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>拘束</p>
            </div>
            <div class="content">
              <p>{{ profile.your_kousoku }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>噛み跡</p>
            </div>
            <div class="content">
              <p>{{ profile.your_kisuma }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>脳イキ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_nouiki }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>アナルセックス</p>
            </div>
            <div class="content">
              <p>{{ profile.your_anal_sex }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>優しい言葉攻め</p>
            </div>
            <div class="content">
              <p>{{ profile.your_word_attack_easy }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>激しい言葉攻め</p>
            </div>
            <div class="content">
              <p>{{ profile.your_word_attack_hard }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>スパンキング</p>
            </div>
            <div class="content">
              <p>{{ profile.your_spanking }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>野外プレイ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_wild }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>潮吹き</p>
            </div>
            <div class="content">
              <p>{{ profile.your_ejaculation }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>イラマチオ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_irrumatio }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>複数人</p>
            </div>
            <div class="content">
              <p>{{ profile.your_multi }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>おもちゃ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_sextoy }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>コスプレ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_cosplay }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>S度</p>
            </div>
            <div class="content">
              <p>{{ profile.your_sadism }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>M度</p>
            </div>
            <div class="content">
              <p>{{ profile.your_masochism }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>エッチの特徴</p>
            </div>
            <div class="content">
              <p style="white-space: pre-line; text-align: left">
                {{ profile.propensity }}
              </p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>経験人数</p>
            </div>
            <div class="content">
              <p>{{ profile.sexual_experience }}</p>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 80px"
          v-show="rec.status_n == 1 || rec.status_n == 2 || rec.status_n == 4 || rec.status_n == 7">
          <div class="col-md-12">
            <button class="btn-comp login-btn" style="background: gray"
              @click="dialog_show = !dialog_show">ブロック</button>
          </div>
        </div>
        <v-dialog v-model="dialog_show" width="500">
          <div style="background: white; color: #222222; padding: 20px">
            <p class="unmatching-confirm">本当に解除しますか？</p>
            <p>解除したマッチンは復活できません。</p>
            <button class="btn-comp login-btn" style="background: gray"
              @click="submitMatchingStatus('ng', 'block')">ブロック</button>
          </div>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.profile {
  margin-top: 80px;
  margin-bottom: 100px;
}

.expand-transition {
  transition: all 0.3s ease;
  height: 30px;
  padding: 10px;
  background-color: #eee;
  overflow: hidden;
}

.expand-enter,
.expand-leave {
  height: 0;
  padding: 0 10px;
  opacity: 0;
}

.matching-success {
  font-size: 3rem;
  font-weight: 800;
  color: #ff8c00;
  background: -webkit-linear-gradient(0deg, #8350de, #df468b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.unmatching-confirm {
  font-size: 1.3rem;
  font-weight: 800;
  color: black;
}

.heart-icon {
  font-size: 3rem;
  font-weight: 800;
  color: #df468b;
  background: -webkit-linear-gradient(0deg, #8350de, #df468b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heart-broken-icon {
  font-size: 3rem;
  font-weight: 800;
  color: #3a3687;
  background: -webkit-linear-gradient(0deg, #8350de, #df468b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.v-input {
  margin-left: 15px;
}

.cont-title {
  width: 150px;
  font-weight: 900;
}

.content {}

.no-border {
  border: none !important;
}

.cont-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /*justify-content:space-between;*/
  padding: 5px;
  border-bottom: 1px dotted #d3d3d3;
}

.profile-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-contents-area {
  margin-top: 15px;
}

.name {
  font-size: 1.3rem;
  font-weight: 900;
}

p {
  margin: 0;
}

.name-area {
  text-align: left;
}

.btn-comp {
  color: white;
  border: none;
  border-radius: 30px;
  width: 80%;
  max-width: 300px;
  /*margin-top: 30px;*/
  padding: 8px;
  font-size: 1.2rem;
  font-weight: 900;
}

.login-btn {
  background: rgb(248, 70, 129);
  background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
}

.signin-btn {
  background: #0081e8;
  margin-top: 3px;
}

.copy-btn {
  background: #775ddf;
  margin-top: 3px;
  font-size: 0.5rem;
}

label {
  border-left: 5px solid;
  border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
  border-image-slice: 0 0 0 5;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 900;
  color: #4b4b4b;
  text-align: left;
  margin-top: 30px;
}

.user-thumbnail {
  width: 100%;
  /* サムネイルの幅を調整 */
  height: 100%;
  /* サムネイルの高さを調整 */
  object-fit: cover;
  /* サムネイルをアスペクト比を保ったまま表示 */
  border-radius: 5px;
  /* サムネイルを円形にする */
  margin-right: 10px;
  /* サムネイルとユーザー名の間の余白 */
}
</style>

<script>
import axios from '../../util/axios'
import Spinner from 'vue-simple-spinner'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import { Carousel, Slide } from 'vue-carousel'
export default {
  name: 'Profile',

  data() {
    return {
      uid: '',
      client: '',
      access_token: '',
      dialog_show: false,
      partner_id: this.$route.query.id,
      profile: [],
      my_profile: [],
      message: '',
      message_flag: false,
      rec: [],
      template_text: '',
      data_all: null,
      isLoading: false,
      showNGReason: false,
      ngReason: '',
      ng_reason_other: '',
      ng_reason_bodytype: [],
      ng_reason_limit_height: '',
      bodytype_list: ['痩せ型', '普通', 'マッチョ', 'ぽっちゃり', 'かなりぽっちゃり'],
      limit_height_list: ['気にしない', '160cm', '165cm', '170cm', '175cm'],
      ng_ok: '',
      showReply: false,
      noReply: false,
      showReviewButton: false,
      matchingDialog: false,
      noMatchingDialog: false,
      textRules: {
        maxLength: (v) => v && v.length <= 50,
      },
      payment_confirmation: 0,
    }
  },
  mounted() {
    this.uid = localStorage.getItem('uid')
    this.client = localStorage.getItem('client')
    this.access_token = localStorage.getItem('access-token')
    this.getAccountData()

    // Tracking user_footprints
    this.trackingProfileView()

    if (this.uid === null) {
      this.$router.push('/login')
    }
  },
  components: {
    Spinner,
    Icon,
    Carousel,
    Slide,
  },
  computed: {
    is_postable() {
      if (this.ngReason != '') {
        if (this.ngReason == '体型（身長、体重）') {
          if (this.ng_reason_bodytype.length != 0 || this.ng_reason_limit_height != '') {
            return true
          } else {
            return false
          }
        } else if (this.ngReason == 'その他') {
          if (this.ng_reason_other != '') {
            if (this.ng_reason_other.length <= 50) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return false
      }
    },
  },
  methods: {
    checkMessageFlag() {
      setTimeout(() => {
        this.message_flag = true
      }, 1000)
      setTimeout(() => {
        this.message_flag = false
      }, 5000)
    },
    setMessage() {
      this.message = '自己紹介メッセージをコピーしました。'
    },
    isReviwable() {
      const status = this.rec.status_n

      if ((status == 1 || status == 2 || status == 7) && this.rec.is_reviewable) {
        return true
      } else {
        return false
      }
    },

    copy_text() {
      navigator.clipboard
        .writeText(this.make_text())
        .then(() => { })
        .catch((e) => {
          console.error(e)
        })
    },
    async submitMatchingStatus(m_status, action) {
      // this.isLoading = true;

      if (action == 'ng') {
        this.ngReason = 'いいねNG'
      }
      if (action == 'block') {
        this.ngReason = 'マッチン解除'
      }
      if (this.ng_reason_other != '') {
        this.ngReason = this.ng_reason_other
      } else if (this.ng_reason_bodytype.length != 0 || this.ng_reason_limit_height != '') {
        if (this.ng_reason_bodytype.length != 0) {
          this.ngReason = 'NG体型:' + this.ng_reason_bodytype.join(',')
        }
        if (this.ng_reason_limit_height != '') {
          this.ngReason = this.ngReason + ' 身長下限:' + this.ng_reason_limit_height
        }
      }

      const result = await axios({
        method: 'POST',
        url: '/api/matching_status',
        headers: {
          'Content-Type': 'application/json',
          uid: this.uid,
          'access-token': this.access_token,
          client: this.client,
        },
        data: {
          recommend: {
            id: this.rec.id,
            status: m_status,
            ng_reason: this.ngReason,
          },
        },
      }).catch((e) => {
        e
        this.$router.push('/login')
      })

      if (!result) {
        // エラーの場合ログイン画面へ遷移させる
        this.$router.push('/login')
        this.isLoading = false
        return
      }
      if (m_status == 'suspend') {
        this.$router.push('home')
      }
      if (m_status == 'ng' && action == 'with_reason') {
        if (this.rec.status_n == 8) {
          this.$router.push('/recommends?status=liked')
        } else {
          this.$router.push('/home')
        }
      }
      if (m_status == 'ng' && action == 'ng') {
        this.$router.push('/recommends?status=liked')
      }
      if (m_status == 'ng' && action == 'block') {
        this.$router.push('/recommends?status=matching&profile_list=true')
      }
      if (m_status == 'ok') {
        this.rec.status_n = 1
      }
      // this.isLoading = false;
    },
    async submitReply(status) {
      this.isLoading = true
      const result = await axios({
        method: 'POST',
        url: '/api/recommend/submit_reply',
        headers: {
          'Content-Type': 'application/json',
          uid: this.uid,
          'access-token': this.access_token,
          client: this.client,
        },
        data: {
          recommend: {
            id: this.rec.id,
            status: status,
          },
        },
      }).catch((e) => {
        e
        this.$router.push('/login')
      })

      if (!result) {
        // エラーの場合ログイン画面へ遷移させる
        this.$router.push('/login')
        this.isLoading = false
        return
      }
      this.isLoading = false
      if (status == 'ng') {
        this.$router.push('/home')
      }
    },

    async getAccountData() {
      this.isLoading = true
      const result = await axios({
        method: 'POST',
        url: '/api/profile',
        headers: {
          'Content-Type': 'application/json',
          uid: this.uid,
          'access-token': this.access_token,
          client: this.client,
        },
        data: {
          user: {
            uid: this.uid,
            partner_id: this.partner_id,
          },
        },
      }).catch((e) => {
        e
        this.$router.push('/login')
      })

      if (!result) {
        // エラーの場合ログイン画面へ遷移させる
        this.$router.push('/login')
        this.isLoading = false
        return
      }
      this.isLoading = false
      this.profile = result.data.profile
      this.my_profile = result.data.my_profile
      this.rec = result.data.recommend
      this.data_all = result.data
      this.payment_confirmation = result.data.payment_conf
      if (this.rec.is_choosable) {
        if (this.rec.status_n == 1) {
          this.showReply = true
        }
      }
    },
    make_text() {
      return (
        '初めまして！' +
        '\n' +
        '\n' +
        '性癖ちゃんからのレコメンドでいいなと思ったのでDMお送りしました！' +
        '\n' +
        'もしよければ仲良くしてくれると嬉しいです✨' +
        '\n' +
        '\n' +
        '\n' +
        '◆アカウント名' +
        '\n' +
        this.my_profile.twitter_name +
        '\n' +
        '\n' +
        '◆TwitterID' +
        '\n' +
        this.my_profile.twitterid +
        '\n' +
        '\n' +
        '◆年齢' +
        '\n' +
        this.my_profile.age +
        '\n' +
        '\n' +
        '◆活動範囲' +
        '\n' +
        this.my_profile.area +
        '\n' +
        '\n' +
        '◆体型' +
        '\n' +
        this.my_profile.body_type +
        '\n' +
        '\n' +
        '◆長所' +
        '\n' +
        this.my_profile.appear_points +
        '\n' +
        '\n' +
        '◆動画の撮影' +
        '\n' +
        this.my_profile.takemovie +
        '\n' +
        '\n' +
        '◆理想的なエッチ' +
        '\n' +
        this.my_profile.sexual_experience +
        '\n' +
        '\n' +
        '【コンタクト】' +
        '\n' +
        '◆DM' +
        '\n' +
        this.my_profile.your_pre_dm +
        '\n' +
        '\n' +
        '◆会う前の電話' +
        '\n' +
        this.my_profile.your_pre_tel +
        '\n' +
        '\n' +
        '◆会う前の写真交換' +
        '\n' +
        this.my_profile.your_pre_photo +
        '\n' +
        '\n' +
        '◆連絡先交換' +
        '\n' +
        this.my_profile.your_pre_contact +
        '\n' +
        '\n' +
        '◆都合のつきやすいタイミング' +
        '\n' +
        this.my_profile.your_freetimes +
        '\n' +
        '\n' +
        '【性癖】' +
        '\n' +
        '◆フェラ' +
        '\n' +
        this.my_profile.your_blow_job +
        '\n' +
        '\n' +
        '◆クンニ' +
        '\n' +
        this.my_profile.your_cunnilingus +
        '\n' +
        '\n' +
        '◆首絞め' +
        '\n' +
        this.my_profile.your_choking +
        '\n' +
        '\n' +
        '◆拘束' +
        '\n' +
        this.my_profile.your_kousoku +
        '\n' +
        '\n' +
        '◆噛み跡' +
        '\n' +
        this.my_profile.your_kisuma +
        '\n' +
        '\n' +
        '◆脳イキ' +
        '\n' +
        this.my_profile.your_nouiki +
        '\n' +
        '\n' +
        '◆アナル' +
        '\n' +
        this.my_profile.your_anal_sex +
        '\n' +
        '\n' +
        '◆優しい言葉責め' +
        '\n' +
        this.my_profile.your_word_attack_easy +
        '\n' +
        '\n' +
        '強い言葉責め' +
        '\n' +
        this.my_profile.your_word_attack_hard +
        '\n' +
        '\n' +
        '◆スパンキング' +
        '\n' +
        this.my_profile.your_spanking +
        '\n' +
        '\n' +
        '◆野外' +
        '\n' +
        this.my_profile.your_wild +
        '\n' +
        '\n' +
        '◆潮吹き' +
        '\n' +
        this.my_profile.your_ejaculation +
        '\n' +
        '\n' +
        '◆イラマチオ' +
        '\n' +
        this.my_profile.your_irrumatio +
        '\n' +
        '\n' +
        '◆複数プレイ' +
        '\n' +
        this.my_profile.your_multi +
        '\n' +
        '\n' +
        '◆コスプレ' +
        '\n' +
        this.my_profile.your_cosplay +
        '\n' +
        '\n' +
        '◆おもちゃ' +
        '\n' +
        this.my_profile.your_sextoy +
        '\n' +
        '\n' +
        '◆私のS度' +
        '\n' +
        this.my_profile.your_sadism +
        '\n' +
        '\n' +
        '◆私のM度' +
        '\n' +
        this.my_profile.your_masochism
      )
    },
    back() {
      if (this.rec.status == 'いいね送付(from男性)') {
        this.$router.push('/recommends?status=liked')
      } else {
        this.$router.push('home')
      }
    },
    async trackingProfileView() {
      try {
        await axios({
          method: 'POST',
          url: "/api/user_footprints",
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
          data: {
            profile_id: this.partner_id,
          }
        });
      } catch (error) {
        console.error("Error tracking profile view:", error);
      }
    }
  },
}
</script>
