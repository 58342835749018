<template>
  <v-main>
    <div class="wrapper">
      <div v-if="isLoading">
        <Spinner />
      </div>
      <div v-else>
        <v-dialog v-model="modalShow" width="500">
          <div style="background: white; color: #222222; padding: 20px">
            <transition name="expand">
              <Icon name="heart" scale="5" style="" class="heart-icon" />
            </transition>
            <p class="matching-success">いいね購入</p>
            <p>購入が完了しました。</p>
          </div>
        </v-dialog>
        <v-dialog v-model="purchase_history" width="500">
          <div style="background: white; color: #222222; padding: 20px">
            <!-- show purchased like history table -->
            <h2 class="matching-success">いいね獲得履歴</h2>
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">残数</th>
                  <th class="text-left">購入日</th>
                  <th class="text-left">有効期限</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ free_like }}</td>
                  <td>2023年10月07日</td>
                  <td>2024年04月04日(有効期限180日)</td>
                </tr>
                <tr v-for="(item, index) in purchased_like_history" :key="index">
                  <td>{{ item.remain }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>{{ item.expiry_date }}</td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </v-dialog>
        <div class="container white-card">
          <v-row>
            <v-col cols="4" style="font-weight: 900" v-if="false">
              詳細閲覧可能
              <br />
              <span class="amount">{{ detail_show_ticket }}</span>
            </v-col>
            <v-col cols="8" style="font-weight: 900" @click="purchase_history = !purchase_history">
              いいね
              <span style="font-size: 0.7rem">(内有料分)</span>
              <br />
              <span class="amount">
                {{ like_remain }}
                <span style="font-size: 0.8rem">({{ purchased_like_remain }})</span>
              </span>
            </v-col>
            <v-col cols="4">
              <v-btn color="primary" style="margin-top: 8px" @click="$router.push('/shop')">いいね購入</v-btn>
            </v-col>
            <v-col cols="12" style="font-weight: 900" v-show="payment_confirmation == 1 && sex == 2">
              <v-switch v-model="hidden_mode" label="プライバシーモード(プレミアムプラン)"></v-switch>
              <p style="font-size: 0.7rem">*オンにするとタイムラインにプロフィールが表示されなくなります。</p>
            </v-col>
          </v-row>
          <span style="font-size: 0.7rem">直近の有効期限:{{ purchased_like_created }} | 購入したいいねから消費されます</span>
        </div>
        <div class="timeline" style="margin-bottom: 80px" v-if="timelineVisible">
          <div class="post-list">
            <div class="post" v-for="(post, index) in posts" :key="index">
              <router-link :to="{ name: 'subprofile', query: { uuid: post.uuid } }">
                <div class="post-user">
                  <img class="user-thumbnail" :src="post.profile1"
                    onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';" />
                  <span style="text-align: left">
                    {{ post.ages }}歳
                    <br />
                    {{ post.contents }}
                  </span>
                </div>
                <div v-if="post.image" class="post-image">
                  <img :src="post.image" :alt="post.content" />
                </div>
              </router-link>
            </div>
          </div>
          <div>
            <v-btn @click="resetTimeline">更新</v-btn>
          </div>
        </div>

        <div class="" style="margin-top: 80px" v-else>
          <p v-if="sex == 1">年齢確認・決済確認が完了次第閲覧可能になります。</p>
          <p v-else>年齢確認が完了次第閲覧可能になります。</p>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import axios from '../../util/axios'
import Spinner from 'vue-simple-spinner'
import Icon from 'vue-awesome/components/Icon'

export default {
  data() {
    return {
      id: '',
      uid: '',
      client: '',
      access_token: '',
      expiry: '',
      token_type: '',
      email: '',
      twitterid: '',
      payment_confirmation: null,
      hidden_mode: false,
      like_remain: 0,
      purchased_like_remain: 0,
      purchased_like_created: '',
      purchased_like_history: [],
      free_like: 0,
      detail_show_ticket: 0,
      newPost: {
        content: '',
        image: null,
      },
      contents: [],
      posts: [],
      t_h: null,
      sex: null,
      age_confirmation: null,
      isLoading: false,
      purchased: this.$route.query.purchased,
      modalShow: false,
      purchase_history: false,
    }
  },
  components: {
    Spinner,
    Icon,
  },
  watch: {
    hidden_mode: function () {
      this.submitHiddenMode()
    },
    // '$route.query.purchased'(value) {
    // 	this.modalShow = value === 'true'
    // }
    // '$route.query'(newQuery) {
    // this.modalShow = newQuery.purchased === 'true'
    // }
  },
  computed: {
    timelineVisible: function () {
      if (this.age_confirmation != 2) {
        return false
      } else if (this.sex == 1 && this.payment_confirmation != 1) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    async getAccountData() {
      this.isLoading = true
      const result = await axios
        .get('/api/timeline', {
          headers: {
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
            'token-type': this.token_type,
            expiry: this.expiry,
          },
        })
        .catch((e) => {
          e
        })
      this.isLoading = false
      this.t_h = result.data
      this.posts = result.data.users
      this.like_remain = result.data.like_remain
      this.free_like = result.data.free_like
      this.purchased_like_remain = result.data.purchased_like_remain
      this.purchased_like_created = result.data.purchased_like_created
      this.purchased_like_history = result.data.purchased_like_history
      this.detail_show_ticket = result.data.detail_show_ticket
      this.hidden_mode = result.data.hidden_mode
      this.payment_confirmation = result.data.payment_confirmation
      this.age_confirmation = result.data.age_confirmation
    },
    async submitHiddenMode() {
      // *hidden_modeの値を送信する
      const result = await axios
        .post(
          '/api/submit_hidden_mode',
          {
            hidden_mode: this.hidden_mode,
          },
          {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
              'token-type': this.token_type,
              expiry: this.expiry,
            },
          },
        )
        .catch((e) => {
          e
        })
      result
    },

    handleFileSelect(event) {
      const file = event.target.files[0]
      if (file) {
        this.newPost.image = URL.createObjectURL(file)
      }
    },

    async resetTimeline() {
      this.isLoading = true
      const result = await axios({
        method: 'POST',
        url: '/api/timeline/reset',
        headers: {
          'Content-Type': 'application/json',
          uid: this.uid,
          'access-token': this.access_token,
          client: this.client,
        },
      }).catch((e) => {
        e
        this.$router.push('/login')
      })
      result
      this.getAccountData()
      this.isLoading = false
    },
  },
  mounted() {
    this.uid = localStorage.getItem('uid')
    this.client = localStorage.getItem('client')
    this.access_token = localStorage.getItem('access-token')
    this.expiry = localStorage.getItem('expiry')
    this.token_type = localStorage.getItem('token_type')
    this.sex = localStorage.getItem('sex')
    this.getAccountData()
    window.scrollTo({ top: 0 })
    if (this.$route.query.purchased != null) {
      this.modalShow = this.$route.query.purchased
    }
  },
  // created() {
  // 	if(this.$route.query.purchased != null){
  // 		this.modalShow = this.$route.query.purchased
  // 	}
  // }
}
</script>

<style scoped>
/* テーブルの境界 */
v-table {
  border-collapse: collapse;
  width: 100%;
}

v-table thead th,
v-table tbody td {
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
}

/* ヘッダーの強調 */
v-table thead {
  background-color: #f5f5f5;
}

v-table thead th {
  font-weight: 600;
}

/* 行のハイライト */
v-table tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

/* フォントサイズとスペーシング */
v-table tbody {
  font-size: 16px;
}

.amount {
  font-weight: 900;
  font-size: 1.3rem;
  background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
  -webkit-background-clip: text;
  color: transparent;
}

.timeline {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.post-form {
  margin-bottom: 20px;
}

.post-form textarea {
  width: 100%;
  height: 100px;
  resize: none;
  border: 1px solid #ccc;
  padding: 10px;
}

.post-form button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.post-list {
  display: flex;
  flex-direction: column;
}

.post {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.post-user {
  display: flex;
  align-items: center;
}

.user-thumbnail {
  width: 120px;
  /* サムネイルの幅を調整 */
  height: 120px;
  /* サムネイルの高さを調整 */
  object-fit: cover;
  /* サムネイルをアスペクト比を保ったまま表示 */
  border-radius: 50%;
  /* サムネイルを円形にする */
  margin-right: 10px;
  /* サムネイルとユーザー名の間の余白 */
}

.post-content {
  padding: 3px;
  text-align: left;
  font-size: 16px;
  margin-bottom: 5px;
}

.post-image {
  margin-top: 10px;
  /* 画像と投稿内容の間の余白 */
}

a {
  text-decoration: none;
  color: black;
}

.wrapper {
  margin-top: 160px;
}
</style>
