<template>
  <div class="chat-view">
    <v-app-bar app style="top: 0">
      <v-toolbar-items>
        <v-btn icon @click="$router.back()" v-if="this.sex == 1">
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        <v-btn icon @click="$router.back()" v-else>
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="username">マッチン運営事務局</div>
      <v-spacer></v-spacer>

      <v-toolbar-items>
        <div style="height: 100%; width: 80px">
          <img :src="'https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png'"
            style="height: 100%; padding: 3px" class="img-fluid rounded-circle"
            onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';" />
        </div>
      </v-toolbar-items>
    </v-app-bar>
    <v-scroll class="chat-bubbles" ref="chatbox" style="padding-top: 50px" name="scroll">
      <div v-for="(message, index) in messages" :key="index">
        <div :class="['chat-bubble', message.sender === 1 ? 'user' : 'other']">
          <admin-chat-bubble :message="convertUrlsToLinks(message.content)" :isUser="message.sender === 1" />
        </div>
      </div>
    </v-scroll>
    <div class="input-container" fixed-bottom>
      <v-text-field v-model="inputMessage" placeholder="メッセージを入力" outlined background="white" />
      <v-btn icon @click="sendMessage()" class="sendIcon" primary>
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
//   import ChatBubble from '@/components/ChatBubble.vue';
import AdminChatBubble from '@/components/AdminChatBubble.vue'
import ActionCable from 'actioncable'
import axios from '../../util/axios'
const cable = ActionCable.createConsumer(process.env.VUE_APP_WEBSOCKET_URL)

export default {
  name: 'ChatView',
  components: {
    //   ChatBubble,
    AdminChatBubble,
  },
  data() {
    return {
      user: null,
      sex: '',
      uid: null,
      uuid: null,
      client: null,
      access_token: null,
      expiry: null,
      token_type: null,
      messageChannel: null,
      inputMessage: '',
      partner_user_id: '',
      cable: null,
      account: null,
      male: null,
      female: null,
      update_read_response: 'suspend',
      messages: [],
      messages_t: [],
      unread_messages_size: 0,
    }
  },
  watch: {
    messages: {
      handler() {
        this.$nextTick(() => {
          this.scrollToBottom() // 新しいメッセージが追加されたらスクロール
        })
      },
      deep: true,
    },
  },
  created() {
    const uid = localStorage.getItem('uid')
    console.log('this.$uid', uid)
    this.messageChannel = cable.subscriptions.create(
      { channel: 'ChatsChannel', uid: uid },
      {
        received: (data) => {
          if (data.message?.content) {
            this.messages.push({
              content: data.message?.content,
              user_id: data.message.user_id,
              created_at: data.message.created_at,
            })
          }
          // this.mesages_t.push(data)
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        },
      },
    )

  },
  mounted() {
    console.log('first2')
    this.uid = localStorage.getItem('uid')
    this.client = localStorage.getItem('client')
    this.access_token = localStorage.getItem('access-token')
    this.expiry = localStorage.getItem('expiry')
    this.token_type = localStorage.getItem('token_type')
    this.uuid = this.$route.query.uuid
    this.getAccountData()

    this.sex = localStorage.getItem('sex')
    // this.submit_already_read();
  },
  methods: {
    convertUrlsToLinks(text) {
      if (!text) return text
      const urlPattern = /(https?:\/\/\S+)|(www\.\S+\.\S+)/gi
      return text.replace(urlPattern, (url) => {
        // "url"をリンクに変換して返す
        return '<a href="' + url + '" target="_blank">' + url + '</a>'
      })
    },
    async getAccountData() {
      const result = await axios
        .get('/api/admin_messages', {
          headers: {
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
            'token-type': this.token_type,
            expiry: this.expiry,
          },
        })
        .catch((e) => {
          e
        })
      console.log('result:', result)

      // this.account = result.data.email;
      this.messages = result.data.admin_messages
      this.unread_messages_size = result.data.unread_messages_size
      localStorage.setItem('unread_messages_size', this.unread_messages_size)
    },
    async submit_already_read() {
      const headers = {
        'Content-Type': 'application/json',
        uid: this.uid,
        'access-token': this.access_token,
        client: this.client,
      }

      const result = await axios
        .post(
          '/api/admin_messages_read',
          {},
          {
            headers: headers,
            params: {
              recommend: {
                uuid: this.uuid,
              },
            },
          },
        )
        .catch((e) => {
          console.log(e)
        })
      this.update_read_response = result.data
    },

    async sendMessage() {
      const headers = {
        'Content-Type': 'application/json',
        uid: this.uid,
        'access-token': this.access_token,
        client: this.client,
      }

      if (this.inputMessage.trim() !== '') {
        const adminMessage = { content: this.inputMessage } // admin_messageをオブジェクトに変換

        const result = await axios
          .post(
            '/api/admin_messages',
            { admin_message: adminMessage }, // admin_messageをオブジェクトとして送信
            { headers: headers },
          )
          .catch((e) => {
            e
          })


        if (result.data.success) {
          this.messages.push({ content: this.inputMessage, sender: 1 })
        }
        this.inputMessage = ''
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },

    scrollToBottom() {
      const chatbox = this.$refs.chatbox
      chatbox.scrollTop = chatbox.scrollHeight
    },
  },
}
</script>

<style scoped>
.chat-view {
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: calc(100vh - 50px);
  /* チャットビュー全体の高さを画面いっぱいに拡張 */
}

.chat-bubble-wrapper {
  flex-shrink: 0;
  /* チャットバブルが画面いっぱいに広がるのを防止 */
  /* ... */
}

.chat-bubbles {
  flex: 1;
  /* チャットバブルを画面いっぱいに拡張 */
  overflow-y: auto;
  /* チャットバブルが画面いっぱいに広がるのを防止 */
  white-space: pre-line;
}

.user {
  display: flex;
  justify-content: flex-end;
}

.other {
  display: flex;
  justify-content: flex-start;
}

.input-container {
  margin-top: auto;
  /* 入力ボックスを下部に配置 */

  display: flex;
  align-items: start;
  /* 垂直方向の中央揃え */
}

.sendIcon {
  padding-top: 20px;
}

.v-toolbar__content {
  top: 0 !important;
  display: fix;
}

.page-wrapper {
  overflow-y: hidden;
}

.username {
  font-size: 1.3rem;
  font-weight: 900;
  color: #707070;
  margin-bottom: 4px;
}
</style>
